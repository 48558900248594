export const screens = [
  {
    title: "Home",
    color: "#ff0055",
    isLink: false,
  },
  {
    title: "Contact",
    color: "#22cc88",
    isLink: true,
    link: "https://linktr.ee/pintly",
  },
];
